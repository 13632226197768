<template>
	<Menu :openCloseForm="openCloseForm" :showForm="showForm" />
	<TweetForm
		:reloadTweets="reloadTweets"
		:openCloseForm="openCloseForm"
		:showForm="showForm"
	/>
	<TweetList :reloadTweets="reloadTweets" :tweets="tweets" />
</template>

<script>
	import { ref } from "vue";
	import Menu from "./components/Menu";
	import TweetForm from "./components/TweetForm";
	import TweetList from "./components/TweetList";
	import useFormTweet from "./hooks/useFormTweet";
	import { getTweetsApi } from "./api/tweet";
	export default {
		name: "App",
		components: {
			Menu,
			TweetForm,
			TweetList,
		},
		setup() {
			let tweets = ref(getTweetsApi().reverse());
			const reloadTweets = () => {
				tweets.value = getTweetsApi().reverse();
			};
			return {
				...useFormTweet(),
				tweets,
				reloadTweets,
			};
		},
	};
</script>

 <style lang="scss">
	//
</style>
